import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Leads from "views/Leads";
import SchemaSettings from "./views/System/Schema";
import UsersSettings from "./views/System/Users";
import EditLead from "./views/EditLead";
import Fornitori from "./views/Fornitori";
import Blocks from "./views/Blocks";
import EditFornitore from "./views/EditFornitore";
import EditBlock from "./views/EditBlock";
import WebIDE from "./views/WebIDE";
import Glossario from "./views/Glossario";
import EditRecord from "./views/EditRecord";

var routes = [
  {
    inMenu: true,
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    inMenu: true,
    path: "/glossario",
    name: "Glossario",
    icon: "ni ni-planet text-blue",
    component: Glossario,
    layout: "/admin"
  },
  {
    inMenu: false,
    path: "/glossario/:oid",
    name: "Modifica Termine",
    icon: "ni ni-planet text-blue",
    component: EditRecord,
    layout: "/admin"
  },
  {
    inMenu: false,
    path: "/schema",
    name: "Schema Dati",
    icon: "ni ni-ui-04",
    component: SchemaSettings,
    layout: "/system"
  },
  {
    inMenu: false,
    path: "/users",
    name: "Utenti",
    icon: "ni ni-ui-04",
    component: UsersSettings,
    layout: "/system"
  }
];
export default routes;
