import React from 'react';
import axios from 'axios';
import AuthService from "../Auth";

// MARK TEST REBUILD

export const BASE_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://localhost:8080" : "https://api.cms.voice.gruppodigitouch.it";

export default class ApiService {
	static login(email, password) {
		return axios.post(`${BASE_URL}/users/login`, { email, password });
	}

	static checkToken(token) {
		return axios.post(`${BASE_URL}/users/check`, {  }, { headers: { authentication: token } });
	}

	static graph(query) {
		return axios.post(`${BASE_URL}/graph`, { query }, { headers: { authentication: AuthService.getToken() } });
	}

	static getRenderedBlockLink(block) {
		return `${BASE_URL}/webtools/blocks/${block}/rendered`;
	}

	// ADMINISTRATION

	static addUser(user) {
		return axios.post(`${BASE_URL}/users/register`, { ...user } , { headers: {authentication: AuthService.getToken() }});
	}

	static addUserPermission(user_id, {type, record_type}) {
		return axios.post(`${BASE_URL}/users/${user_id}/permissions/add`, { type, record_type } , { headers: {authentication: AuthService.getToken() }});
	}

	static removeUserPermission(user_id, {type, record_type}) {
		return axios.post(`${BASE_URL}/users/${user_id}/permissions/remove`, { type, record_type } , { headers: {authentication: AuthService.getToken() }});
	}

	static removeAllUserPermissions(user_id) {
		return axios.post(`${BASE_URL}/users/${user_id}/permissions/removeall`, {  } , { headers: {authentication: AuthService.getToken() }});
	}

	static createRecord(type, data, state) {
		return axios.post(`${BASE_URL}/${type}/create`, { data: JSON.stringify(data), state, meta: "{}" } , { headers: {authentication: AuthService.getToken() }})
	}

	static editRecord(type, oid, data, state) {
		return axios.post(`${BASE_URL}/${type}/${oid}/edit`, { data, state } , { headers: {authentication: AuthService.getToken() }});
	}

	static deleteRecord(oid) {
		return axios.get(`${BASE_URL}/records/${oid}/delete`, { headers: {authentication: AuthService.getToken() }});
	}

	static setRecordState(oid, state) {
		return axios.post(`${BASE_URL}/records/${oid}/state/edit`, { state } , { headers: {authentication: AuthService.getToken() }});
	}

	static export(type) {
		//return axios.get(`${BASE_URL}/${type}/export`, { headers: { authentication: AuthService.getToken() }});
		return fetch(`${BASE_URL}/${type}/export`, { method: "get", headers: {authentication: AuthService.getToken() } });
	}

	static createTopology(topology) {
		return axios.post(`${BASE_URL}/archetypes/create`, { topology } ,{ headers: {authentication: AuthService.getToken() }});
	}

	static editTopology(oid, topology) {
		return axios.post(`${BASE_URL}/archetypes/${oid}/edit`, { topology } , { headers: {authentication: AuthService.getToken() }});
	}

	static addTopologyField(oid, field) {
		return axios.post(`${BASE_URL}/archetypes/${oid}/fields/add`, { field } , { headers: {authentication: AuthService.getToken() }});
	}

	static removeTopologyField(oid, key) {
		return axios.get(`${BASE_URL}/archetypes/${oid}/fields/${key}/remove`, { headers: {authentication: AuthService.getToken() }});
	}

	static createPipeline(pipeline) {
		return axios.post(`${BASE_URL}/pipelines/create`, { pipeline } , { headers: {authentication: AuthService.getToken() }});
	}

	static editPipeline(oid, pipeline) {
		return axios.post(`${BASE_URL}/pipelines/${oid}/edit`, { pipeline } , { headers: {authentication: AuthService.getToken() }});
	}

	static deletePipeline(oid) {
		return axios.get(`${BASE_URL}/pipelines/${oid}/remove`, { headers: {authentication: AuthService.getToken() }});
	}

	static createTrigger(trigger) {
		return axios.post(`${BASE_URL}/triggers/create`, { trigger } , { headers: {authentication: AuthService.getToken() }})
	}

	static editTrigger(oid, trigger) {
		return axios.post(`${BASE_URL}/triggers/${oid}/edit`, { trigger } , { headers: {authentication: AuthService.getToken() }});
	}

	static deleteTrigger(oid) {
		return axios.get(`${BASE_URL}/triggers/${oid}/remove`, { headers: {authentication: AuthService.getToken() }});
	}

	static runTrigger(trigger) {
		return axios.get(`${BASE_URL}/triggers/${trigger}/runnow`, { headers: {authentication: AuthService.getToken() }});
	}
}